<template>
  <transition>
    <transition :name="routerTransition" mode="out-in">
      <router-view />
    </transition>
  </transition>
</template>
<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions([
    ]),
  },
  created() {
    
    this.$store.dispatch('getFacultyName');
    this.$store.dispatch('getNamesUniversity');



  },



  setup() {
    const { routerTransition } = useAppConfig();
    return {
      // App Config
      routerTransition,
    };
  },
};
</script>